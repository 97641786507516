<template>
  <v-row class="page page--account" v-if="!isFirstLoad">
    <v-col cols="12" md="5">
      <h1 class="page__title">
        {{ $t('nav_settings_company') }}
      </h1>

      <component
        class="account__company"
        ref="companyInfo"
        :is="companySectionComponent"
        :data="$store.state.companyData"
        @submit="editCompanySuccess"
      />

      <div v-if="isEditingCompanyInfo">
        <v-btn
          class="pkmn-button pkmn-button--has-right-sibling"
          color="primary"
          outlined
          @click="isEditingCompanyInfo = false"
        >
          {{ $t('form_cancel') }}
        </v-btn>
        <v-btn
          class="pkmn-button"
          color="primary"
          @click="submitEditCompanyInfo"
        >
          {{ $t('form_save') }}
        </v-btn>
      </div>

      <div v-else>
        <v-btn
          v-if="auth.checkPermission('edit-company')"
          class="pkmn-button pkmn-button--full-width-mobile"
          color="primary"
          @click="isEditingCompanyInfo = true"
        >
          {{ $t('form_edit') }}
        </v-btn>
      </div>
    </v-col>

    <v-col cols="12" md="5" offset-md="2">
      <h1 class="page__title">
        {{ $t('nav_settings_personal') }}
      </h1>

      <component
        class="account__user"
        ref="userInfo"
        :is="userSectionComponent"
        :data="$store.state.userData"
        @submit="editUserSuccess"
      />

      <div v-if="isEditingUserInfo">
        <v-btn
          class="pkmn-button pkmn-button--has-right-sibling"
          color="primary"
          outlined
          @click="isEditingUserInfo = false"
        >
          {{ $t('form_cancel') }}
        </v-btn>
        <v-btn class="pkmn-button" color="primary" @click="submitEditUserInfo">
          {{ $t('form_save') }}
        </v-btn>
      </div>

      <div v-else>
        <v-btn
          v-if="false"
          class="pkmn-button pkmn-button--has-border pkmn-button--has-right-sibling pkmn-button--full-width-mobile"
          color="primary"
          @click="isEditingUserInfo = true"
        >
          {{ $t('form_edit') }}
        </v-btn>

        <v-btn
          class="pkmn-button pkmn-button--full-width-mobile"
          color="primary"
          outlined
          @click="auth.logout"
        >
          {{ $t('g_log_out') }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import auth from '@/auth'
import helpers from '@/helpers'
import userHelpers from '@/helpers/user'

import DataCompanyInfo from '@/components/Settings/DataCompanyInfo'
import FormCompanyInfo from '@/components/Settings/FormCompanyInfo'
import DataUserInfo from '@/components/Account/DataUserInfo'
import FormUserInfo from '@/components/Account/FormUserInfo'

export default {
  name: 'AccountView',
  components: { DataCompanyInfo, FormCompanyInfo, DataUserInfo, FormUserInfo },
  data() {
    return {
      auth: auth,
      isEditingCompanyInfo: false,
      isEditingUserInfo: false,
      isFirstLoad: true,
      profiles: [],
    }
  },
  computed: {
    companySectionComponent() {
      return this.isEditingCompanyInfo ? FormCompanyInfo : DataCompanyInfo
    },
    userSectionComponent() {
      return this.isEditingUserInfo ? FormUserInfo : DataUserInfo
    }
  },
  methods: {
    submitEditCompanyInfo() {
      helpers.sendTrackingEvent('MAIN PAGE', 'submit_updated_company_info')
      this.$refs.companyInfo.submitForm()
    },
    editCompanySuccess() {
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('updated_confirm_message', {
          object: this.$t('nav_settings_company')
        })
      })
      this.isEditingCompanyInfo = false
    },
    submitEditUserInfo() {
      // @todo: track data
      this.$refs.userInfo.submitForm()
    },
    async editUserSuccess() {
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('updated_confirm_message', {
          object: this.$t('nav_settings_personal')
        })
      })
      this.isEditingUserInfo = false

      // fetch the employee list again to reflect the change
      await userHelpers.fetchGroupEmployees(this.$store.state.selectedGroup)
    }
  },
  async mounted() {
    if (!this.$store.state.companyData) {
      await helpers.fetchDashboardData()
    }

    this.isFirstLoad = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.account__company,
.account__user {
  margin-bottom: 32px;
}
</style>
